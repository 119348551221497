import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import { withTheme } from "styled-components"
import { faGithubSquare, faLinkedin, faTwitterSquare } from "@fortawesome/free-brands-svg-icons"
import { faEnvelopeSquare, faRssSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import HeaderIcon from "../HeaderIcon"
import ProgressBar from "./ProgressBar"
import config from "../../../customize"

const Header = ({ siteTitle, showTitle, isPostTemplate }) => {
  return (
    <StyledMainHeader className="main-header">
      {/* Google AdSense */}
      {config.googleAdSenseId && config.googleAdSenseId !== "" && (
        <script
          data-ad-client={config.googleAdSenseId}
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
      )}

      {isPostTemplate && config.useScrollIndicator && <ProgressBar />}

      <StyledMainHeaderInner className="main-header-inner">
        <span>{showTitle && <Link to="/">{`${siteTitle}`}</Link>}</span>
        <StyledMediaIcons>
          <HeaderIcon
            accountInfo={config.socialMediaLinks.email}
            mediaName={"email"}
            preHref={"mailto:"}
            icon={faEnvelopeSquare}
          />

          <HeaderIcon
            accountInfo={config.socialMediaLinks.github}
            mediaName={"github"}
            preHref={"https://github.com/"}
            icon={faGithubSquare}
          />

          <HeaderIcon
            accountInfo={config.socialMediaLinks.twitter}
            mediaName={"twitter"}
            preHref={"https://twitter.com/"}
            icon={faTwitterSquare}
          />

          <HeaderIcon
            accountInfo={config.socialMediaLinks.linkedIn}
            mediaName={"linkedin"}
            preHref={"https://linkedin.com/"}
            icon={faLinkedin}
          />

          {/*<Link className="icon-fa-link" to="/rss.xml">*/}
          {/*  <FontAwesomeIcon className="icon-fa fa-rss-square" icon={faRssSquare} />*/}
          {/*</Link>*/}

        </StyledMediaIcons>
      </StyledMainHeaderInner>
    </StyledMainHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default withTheme(Header)

const StyledMainHeader = styled.header`
  height: 55px;
  margin-top: ${config.useScrollIndicator ? "-5px" : "0"};
  margin-bottom: 1rem;
`

const StyledMainHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  margin: 0 auto;
  max-width: ${props => props.theme.maxWidthSite};
  padding: 0.6rem;
  span {
    font-family: 'Playfair Display',serif;
    font-weight: 400;
    font-size: 1.4rem;
    // margin-bottom: 0.0rem;
    // TODO hack hack hack
    // * !span instead of h1 to avoid 2 h1's on a single page and de-emphasize what is essentially a nav link;
    // * font options should be derived from the typography theme but I'm yet to find out how to do this properly;
    // * margin-bottom: 0.0rem; was the only thing working to vertically center h1, but it's not necessary for a span, whatever
  }
`

const StyledMediaIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  * {
    font-size: 1.7rem;
  }

  @media (max-width: 400px) {
    * {
      margin: 0 0.15rem;
    }
  }
`
