/* Customization Guide available on:
  https://gatsby-blog-mdx.now.sh/2020/05/4-customize/
  ======================================== */
const config = {
  /* Site
  ========================================= */
  title: `Jura Gorohovsky`,          // Displayed in header
  description: `Tennis fan, occasional Product Manager, amateur software developer, impostor.`,     // Site description
  maxWidth: "1024px",                  // Max width of website
  siteUrl: `https://gorohovsky.com`, // For sitemap & RSS feed
  useScrollIndicator: true,           // Use scroll indicator on top of screen in posts

  /* Profile
  ========================================= */
  author: `Jura Gorohovsky`,                             // Name shows on profile
  profileDescription: "Tennis fan, occasional Product Manager, amateur software developer, impostor.",   // Shows under author name
  profileImageName: `images/jura_gorohovsky_160x160.png`,                  // Place profile.jpg/.jpeg/.png in _assets folder
  location: "",                                      // Location under profileDescription. "" --> disabled
  footerLink: "",                                    // Link to page when you click footer name

  /* Social Media Links
      accountName & emailAddress: leave blank ("") to disable
      showHeaderIcon: shows social media icon in header. When true, must have account name set
  ========================================= */
  socialMediaLinks: {
    email:     { emailAddress: "gorohoroh@gmail.com", showHeaderIcon: true },
    github:    { accountName: "gorohoroh", showHeaderIcon: true },
    twitter:   { accountName: "gorohoroh", showHeaderIcon: true },
    linkedIn:  { accountName: "in/jura-gorohovsky/", showHeaderIcon: true },   // Use URL after "linkedin.com/"
    errorism:  { url: "https://errorism.dev", showHeaderIcon: false }
  },

  /* Social Media Share Buttons--available below every post
  ========================================= */
  shareButtons: { email: false, facebook: false, twitter: false, reddit: false, linkedIn: false },

  /* Comments
  ========================================= */
  comments: {
    facebook: { enabled: false, appId: "" },      // appId: optional, but used to moderate fb comments
    disqus: { enabled: false, shortName: "" },   // Check your shortName on https://disqus.com
    utterances: { enabled: false, repoUrl: "" }, // i.e. 'ellismin/gatsby-blog-mdx'
  },

  /* More Custom Settings
  ========================================= */
  defaultTheme: "light",                      // Options: dark / light -- applied to first visitors
  showTimeToRead: false,                      // Shows time to read for each post in main page
  breakCodeLines: false,                      // Breaks long code lines instead of having horizontal scroll
  gaTrackingId: `UA-168225359-1`,             // Your google analytics tracking id--i.e. UA-*****
  googleAdSenseId: ``,                        // Your google AdSense client id--i.e. ca-pub-****
}

module.exports = config
